import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import cam from "../images/cam.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `punkrockvideos`,
          `punkrockvideos.com`,
          `punk`,
          `punkrock`,
          `records`,
          `VHS`,
          `video`,
          `skateboard`,
          `stickers`,
          `vnyl records`,
          `punk records`,
        ]}
        title="Home"
      />

      <section className="text-center">
        <h2 className="text-3xl">
          For Sale videos, records, and stickers collection
        </h2>
        <img alt="Logo" className="block mx-auto w-1/2" src={cam} />
           <p className="text-3xl">
            My Ebay{` `}
            <a
              className="font-bold no-underline text-black"
              href="https://www.ebay.com/usr/punkrockvideos"
            >
              Listings
            </a>
          </p>
      </section>
    </Layout>
  );
}

export default IndexPage;
